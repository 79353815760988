<template>
  <v-list class="!bg-transparent" density="compact" nav>
    <v-list-item
      v-for="item in items"
      :key="item.label"
      :active="isActive(item.to)"
      active-class="[&_.v-list-item-title]:!font-semibold"
      :href="item.href"
      :target="item.target ?? (item.href ? '_blank' : undefined)"
      :title="item.label"
      :to="item.to"
      v-on="item.listeners ?? {}"
    >
      <template #prepend>
        <app-icon v-if="item.icon" class="mr-3" :icon="item.icon" :size="18" />
      </template>
    </v-list-item>
  </v-list>
</template>

<script lang="ts" setup>
import type { MenuListItem } from "@/shared/menu/menu.model";

defineProps<{
  items: MenuListItem[];
}>();

const router = useRouter();
const routes = router.getRoutes();

const isActive = (to: MenuListItem["to"]) => {
  if (!to) return false;

  const itemRoute = router.resolve(to);
  const currentRoute = useRoute();

  if (itemRoute.name === currentRoute.name) return true;

  const match = routes.find((route) => route.name === itemRoute.name);

  if (!match) return false;

  return currentRoute.path.startsWith(match.path);
};
</script>
